import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import Login from './components/auth/Login';
import NuevaCuenta from './components/auth/NuevaCuenta';
import Proyectos from './components/proyectos/Proyectos';

import ProyectoState from './context/proyectos/proyectoState';
import TareaState from './context/tareas/tareaState';
import AlertaState from './context/alertas/alertaState';
import AuthState from './context/autenticacion/authState';
import tokenAuth from './config/token';

//import RutaPrivada from './components/rutas/RutaPrivada';

// Revisar si tenemos un token
const token = localStorage.getItem('token');
if(token) {
  tokenAuth(token);
}

function App() {
  return (
    <Router>
    <ProyectoState>
      <TareaState>
        <AlertaState>
          <AuthState>
            <Router>
                <>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/nueva-cuenta" component={NuevaCuenta} />
                    <Route exact path="/proyectos" component={Proyectos} />
                </>
            </Router>
          </AuthState>
        </AlertaState>
      </TareaState>
    </ProyectoState>
    </Router>
  );
}

export default App;
